import { AppBar, Button, Container, Stack, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useAlertStore } from 'entities/alert'
import { CallMeDialog } from 'features/call-me-dialog'
import { LinkRouter, RequiredPropsLinkRouter } from 'features/link-router'
import { WithMetricProps } from 'features/link-router/ui/LinkRouter'
import { LinkedLogo } from 'features/linked-logo'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppRoutesNames } from 'shared/configs/router-consts'
import useTypedNavigate from 'shared/lib/hooks/useTypedNavigate'
import { ymUse } from 'shared/lib/ya-metrics-wrap'

import { GridBox, PaddingBox, StyledLink } from './styled'

interface Props {
  linksConst?: (RequiredPropsLinkRouter & WithMetricProps)[]
  showOrderButton?: boolean
}

export const Header = observer(({ linksConst, showOrderButton }: Props) => {
  const { t } = useTranslation()

  const stringToLink = useCallback(() => {
    const text = t('Phone')

    return `tel:${text.replaceAll(/\s|-|\(|\)/g, '').replace('8', '+7')}`
  }, [t])

  const theme = useTheme()

  const { setAlert } = useAlertStore()
  const [openDialog, setOpenDialog] = useState(false)

  const { typedNavigate } = useTypedNavigate()

  const handleOrderClick = useCallback(() => {
    typedNavigate({ key: AppRoutesNames.main })

    document
      .getElementById('address_inputs')
      ?.scrollIntoView({ behavior: 'smooth', block: 'start' })

    ymUse({
      action: 'Click',
      source: 'Header',
      target: 'DeliveryButton',
    })
  }, [typedNavigate])

  const handleClickPhone = useCallback(
    () =>
      ymUse({
        action: 'Click',
        source: 'Header',
        target: 'PhoneLink',
      }),
    []
  )

  const handleCloseDialog = () => setOpenDialog(false)

  const handleClickLogo = useCallback(
    () => ymUse({ action: 'Click', source: 'Header', target: 'Logo' }),
    []
  )

  return (
    <AppBar
      data-testid="AppBar-d6b35d7bf53845808ba246370031c4b7"
      color="transparent"
      position="static"
      elevation={0}
      sx={{ marginBottom: '40px' }}>
      <Container>
        <GridBox>
          <Box onClick={handleClickLogo}>
            <LinkedLogo size="large" color={theme.palette.common.black} />
          </Box>
          <PaddingBox ml={'40px'}>
            <Stack direction={'row'} spacing={2}>
              {linksConst?.map((el, i) => (
                <LinkRouter
                  key={`header_${el.translationkey}_${i}`}
                  to={el.to}
                  translationkey={el.translationkey}
                  whiteSpace="nowrap"
                  metric={el.metric}
                />
              ))}
            </Stack>
          </PaddingBox>
          <Stack direction={'row'}>
            <PaddingBox justifySelf={'right'} ml={'5px'}>
              <Stack direction={'row'}>
                <StyledLink
                  href={stringToLink()}
                  underline="none"
                  color="inherit"
                  variant="body1"
                  whiteSpace="nowrap"
                  onClick={handleClickPhone}>
                  {t('Phone')}
                </StyledLink>
              </Stack>
            </PaddingBox>

            <Stack direction={'row'} alignItems={'center'}>
              <CallMeDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                setOpenAlert={setAlert}
              />

              {/* <Button data-testid="Button-32805f465ca84a75bdb7ec6427b5f80d"    
                variant="outlined"
                color="secondary"
                sx={{ height: '36px', whiteSpace: 'nowrap' }}
                onClick={handleClickDialog}>
                {t('Button.CallMe')}
              </Button> */}

              {showOrderButton && (
                <Button
                  data-testid="Button-05568472bb754ce182cf54969715f4a7"
                  sx={{ ml: '8px', height: '36px', whiteSpace: 'nowrap' }}
                  variant="contained"
                  color="primary"
                  onClick={handleOrderClick}>
                  {t('Button.OrderFuel')}
                </Button>
              )}
            </Stack>
          </Stack>
        </GridBox>
      </Container>
    </AppBar>
  )
})
