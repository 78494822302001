/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Delivery B2B API
 * API b2b сервиса доставки топлива
 * OpenAPI spec version: 12.12.24
 */

/**
 * Город к которому относится зона
 */
export type AreaCity = (typeof AreaCity)[keyof typeof AreaCity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AreaCity = {
  moscow: 'moscow',
  saint_petersburg: 'saint_petersburg',
} as const
